.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; 
    /* background: linear-gradient(319deg, #c2eaba 0%, #c5f4e0 37%, #efc2cf 100%); */
    margin: 5%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
  }
  
  .contact-info::before {
    content: "";
    background: linear-gradient(319deg, #c2eaba 0%, #c5f4e0 37%, #efc2cf 100%);
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.40;
  }

/* .contact-form {
  background-color: white;
  position: relative;
  margin: 3% 20%;
  display: flex;
  flex-direction: column;
} */

.contact-subject-div input,
.contact-name-div input,
.contact-email-div input,
.contact-message-div input {
  width: 500px;
  height: 25px;
  background-color: white;
  position: relative;
  margin: 1%;
  border: 1px solid white;
  font-style: italic;
  padding: 1%;
  border-radius: 5px;
}

.contact-message-div input {
  height: 60px;
  
}

.contact-subject-div,
.contact-name-div,
.contact-email-div,
.contact-message-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  margin: 0 20%;
  justify-content: center;
}

.contact-submit {
  width: 200px;
  height: 30px;
  margin: 2%;
  border:none;
  border-radius: 3px;
  background-color: lightgrey;
}

.contact-form h2 {
  margin-top: 30px;
}

.contact-subject-div {
  margin-top: 30px;
}