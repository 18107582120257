/* header */

.linear-gradient {
    margin: 2% 5%;
    padding: 3%;
    /* background: linear-gradient(45deg, lavender, #df977f); */
    /* background: linear-gradient(45deg, #df977f, #ffcbf2, #f3c4fb, #ecbcfd, #deaaff, #d8bbff, #d0d1ff, #c8e7ff, #c0fdff); */
    /* background-color: #c2eaba; */
    margin-top: 50px;
    position: relative;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .linear-gradient::before {
    content: "";
    background: linear-gradient(319deg, #c2eaba 0%, #c5f4e0 37%, #efc2cf 100%);
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.30;
  }
  
  .ellenora {
    font-size: 2em;
    margin: 0;
    text-align: center;
    position: relative;
    opacity: .70;
  }
  
  .full-stack {
    font-size: 20px;
    margin: 1%;
    text-align: center;
    position: relative;
    opacity: .50;
  }


  @media screen and (max-width: 600px) {

 
  }