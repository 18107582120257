/* about me page */

.about-container {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 40% 40% 20%;
    grid-template-areas:
    "headshot text-one"
    "headshot text-two"
    "headshot resume";
    margin: 5%;
    position: relative;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .about-container::before {
    content: "";
    background: radial-gradient(#c2eaba 0%, #c5f4e0 37%, #efc2cf 100%);
    background-size: cover;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.30;
    z-index: -1;
  }
  
  .headshot {
  width: 60%;
  height: auto;
  margin: 5%;
  margin-left: 10%;
  /* border: 5px solid white; */
  /* position: relative; */
  grid-area: headshot;
  align-self: center;
  }
  
  .about-me-text-one,
  .about-me-text-two {
  width: 85%;
  font-size: 19px;
  position: relative;
  /* margin-top: 5%; */
  color: darkslategrey;
  
  }

  .about-me-text-one {
  grid-area: text-one;
  margin-top: 5%;
 
  }

  .about-me-text-two {
    grid-area: text-two;
    margin-top: 5%;
  }

  .headshot-resume-container {
    /* display: flex;
    flex-direction: column;
    justify-content: space-evenly; */
  }

  .about-text-container {
    /* display: flex;
    flex-direction: column; */
  }

  .view-resume {
    width: 200px;
    height: 30px;
    margin-right: 15%;
    border:none;
    border-radius: 3px;
    background-color: #dcdcdc;
    grid-area: resume;
    justify-self: end;
    align-self: center;
  }

  @media screen and (max-width: 1000px) {
    .headshot {
      width: 75%;
      align-self: flex-start;
      margin-top: 10%;
    }

    .about-me-text-two {
      margin-top: 7%;
    }
  }
  
  @media screen and (max-width: 600px) {

    .about-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-container {
      display: grid;
      grid-template-columns: 90%;
      grid-template-rows: 50% 20% 20% 10%;
      grid-template-areas:
      "headshot"
      "text-one"
      "text-two"
      "resume";
    }

    .about-me-text-one,
    .about-me-text-two {
      justify-self: center;
      text-align: center;
      width: 95%;
      font-size: 16px;
    }

    .about-me-text-two {
      margin-top: 10%;
    }

    .headshot {
      width: 55%;
      justify-self: center;
      margin: 0 auto;
      margin-top: 15%;
    }

    .view-resume {
      justify-self: center;
      margin: 0 auto;
    }

    .about-me-text {
        width: 75%;
        text-align: center;
    }

  }

  @media screen and (max-width: 480px) {
    .about-container {
      grid-template-rows: 40% 40% 10%;
      grid-template-areas:
      "text-one"
      "text-two"
      "resume";
    }

    .headshot {
      display: none;
    }

    .about-me-text-one,
    .about-me-text-two {
      text-align: justify;
      margin: 10%;
      font-size: 12px;
    }

    .about-me-text-two {
      height: 75%;
    }
  }