* {
  background-color: 
}


/* linear gradient backgrounds */

.linear-gradient-bb,
.linear-gradient-meme,
.linear-gradient-sub,
.linear-gradient-mindfront,
.linear-gradient-wavculture {
  margin: 5%;
  padding: 3%;
  background-color: #f8f9fa;
  /* background-color: #e5e5e5; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* position: relative */
  /* background: linear-gradient(45deg, #ffe5d9, lavender); */
}

/* .linear-gradient-bb:hover::before,
.linear-gradient-meme:hover::before,
.linear-gradient-sub:hover::before,
.linear-gradient-mindfront:hover::before,
.linear-gradient-wavculture:hover::before {
  content: "";
  background: linear-gradient(319deg, #c2eaba 0%, #c5f4e0 37%, #efc2cf 100%);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.30;
} */

.linear-gradient-bb:hover,
.linear-gradient-meme:hover,
.linear-gradient-sub:hover,
.linear-gradient-mindfront:hover,
.linear-gradient-wavculture:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}









