.resume-container {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 5%;
    padding: 3%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    justify-content: center;
  }
  
  .resume-container::before {
    content: "";
    background: linear-gradient(45deg, #c2eaba 0%, #c5f4e0 37%, #efc2cf 100%);
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.30;
  }

.resume-container p {
  font-size: 20px;
  
}

.LI, .GH {
  width: 40px;
  height: auto;
  opacity: .30;
  margin: 0 30px;
}