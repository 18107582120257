
/* projects */

.accordion {
  margin-left: 5%;
  margin-right: 5%;
}





.site-links {
    width: 60%;
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
  }
  
  .site-links a {
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    position: relative;
  }
  
  .bed-bug,
  .meme,
  .sub,
  .mindfront,
  .wavculture {
    width: 75%;
  }
  
  .bed-bug-div,
  .meme-div,
  .sub-div,
  .mindfront-div,
  .wavculture-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .bb-title,
  .meme-title,
  .sub-title,
  .mindfront-title,
  .wavculture-title {
    font-size: 30px;
    margin: 5px;
    position: relative;
  }
  
  .bb-desc,
  .meme-desc,
  .sub-desc,
  .mindfront-desc,
  .wavculture-desc {
    font-size: 20px;
    position: relative;
  }
  
  
  .skills-title,
  .projects-title {
    text-align: center;
    padding-top: 50px;
    margin-bottom: 50px;
    opacity: .70;
  }