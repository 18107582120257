.skills-icons {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    margin: 5%;
    padding: 3%;
  
  }

  
  .skills-icons img {
    width: 60px;
    height: auto;
    padding-bottom: 15%;
    justify-self: center;
    align-self: center;
    opacity: .50;
  }

  @media screen and (max-width: 600px) {

    .skills-icons img {
        width: 40px;
    }

  }

