@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
    /* 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
    /* sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  background-color: 
}


/* linear gradient backgrounds */

.linear-gradient-bb,
.linear-gradient-meme,
.linear-gradient-sub,
.linear-gradient-mindfront,
.linear-gradient-wavculture {
  margin: 5%;
  padding: 3%;
  background-color: #f8f9fa;
  /* background-color: #e5e5e5; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* position: relative */
  /* background: linear-gradient(45deg, #ffe5d9, lavender); */
}

/* .linear-gradient-bb:hover::before,
.linear-gradient-meme:hover::before,
.linear-gradient-sub:hover::before,
.linear-gradient-mindfront:hover::before,
.linear-gradient-wavculture:hover::before {
  content: "";
  background: linear-gradient(319deg, #c2eaba 0%, #c5f4e0 37%, #efc2cf 100%);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.30;
} */

.linear-gradient-bb:hover,
.linear-gradient-meme:hover,
.linear-gradient-sub:hover,
.linear-gradient-mindfront:hover,
.linear-gradient-wavculture:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}










/* header */

.linear-gradient {
    margin: 2% 5%;
    padding: 3%;
    /* background: linear-gradient(45deg, lavender, #df977f); */
    /* background: linear-gradient(45deg, #df977f, #ffcbf2, #f3c4fb, #ecbcfd, #deaaff, #d8bbff, #d0d1ff, #c8e7ff, #c0fdff); */
    /* background-color: #c2eaba; */
    margin-top: 50px;
    position: relative;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .linear-gradient::before {
    content: "";
    background: linear-gradient(319deg, #c2eaba 0%, #c5f4e0 37%, #efc2cf 100%);
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.30;
  }
  
  .ellenora {
    font-size: 2em;
    margin: 0;
    text-align: center;
    position: relative;
    opacity: .70;
  }
  
  .full-stack {
    font-size: 20px;
    margin: 1%;
    text-align: center;
    position: relative;
    opacity: .50;
  }


  @media screen and (max-width: 600px) {

 
  }
/* about me page */

.about-container {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 40% 40% 20%;
    grid-template-areas:
    "headshot text-one"
    "headshot text-two"
    "headshot resume";
    margin: 5%;
    position: relative;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .about-container::before {
    content: "";
    background: radial-gradient(#c2eaba 0%, #c5f4e0 37%, #efc2cf 100%);
    background-size: cover;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.30;
    z-index: -1;
  }
  
  .headshot {
  width: 60%;
  height: auto;
  margin: 5%;
  margin-left: 10%;
  /* border: 5px solid white; */
  /* position: relative; */
  grid-area: headshot;
  align-self: center;
  }
  
  .about-me-text-one,
  .about-me-text-two {
  width: 85%;
  font-size: 19px;
  position: relative;
  /* margin-top: 5%; */
  color: darkslategrey;
  
  }

  .about-me-text-one {
  grid-area: text-one;
  margin-top: 5%;
 
  }

  .about-me-text-two {
    grid-area: text-two;
    margin-top: 5%;
  }

  .headshot-resume-container {
    /* display: flex;
    flex-direction: column;
    justify-content: space-evenly; */
  }

  .about-text-container {
    /* display: flex;
    flex-direction: column; */
  }

  .view-resume {
    width: 200px;
    height: 30px;
    margin-right: 15%;
    border:none;
    border-radius: 3px;
    background-color: #dcdcdc;
    grid-area: resume;
    justify-self: end;
    align-self: center;
  }

  @media screen and (max-width: 1000px) {
    .headshot {
      width: 75%;
      -webkit-align-self: flex-start;
              align-self: flex-start;
      margin-top: 10%;
    }

    .about-me-text-two {
      margin-top: 7%;
    }
  }
  
  @media screen and (max-width: 600px) {

    .about-container {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }

    .about-container {
      display: grid;
      grid-template-columns: 90%;
      grid-template-rows: 50% 20% 20% 10%;
      grid-template-areas:
      "headshot"
      "text-one"
      "text-two"
      "resume";
    }

    .about-me-text-one,
    .about-me-text-two {
      justify-self: center;
      text-align: center;
      width: 95%;
      font-size: 16px;
    }

    .about-me-text-two {
      margin-top: 10%;
    }

    .headshot {
      width: 55%;
      justify-self: center;
      margin: 0 auto;
      margin-top: 15%;
    }

    .view-resume {
      justify-self: center;
      margin: 0 auto;
    }

    .about-me-text {
        width: 75%;
        text-align: center;
    }

  }

  @media screen and (max-width: 480px) {
    .about-container {
      grid-template-rows: 40% 40% 10%;
      grid-template-areas:
      "text-one"
      "text-two"
      "resume";
    }

    .headshot {
      display: none;
    }

    .about-me-text-one,
    .about-me-text-two {
      text-align: justify;
      margin: 10%;
      font-size: 12px;
    }

    .about-me-text-two {
      height: 75%;
    }
  }

/* projects */

.accordion {
  margin-left: 5%;
  margin-right: 5%;
}





.site-links {
    width: 60%;
    display: -webkit-flex;
    display: flex;
    margin: 0 auto;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    position: relative;
  }
  
  .site-links a {
    text-decoration: none;
    color: black;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 10px;
    position: relative;
  }
  
  .bed-bug,
  .meme,
  .sub,
  .mindfront,
  .wavculture {
    width: 75%;
  }
  
  .bed-bug-div,
  .meme-div,
  .sub-div,
  .mindfront-div,
  .wavculture-div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
  }
  
  .bb-title,
  .meme-title,
  .sub-title,
  .mindfront-title,
  .wavculture-title {
    font-size: 30px;
    margin: 5px;
    position: relative;
  }
  
  .bb-desc,
  .meme-desc,
  .sub-desc,
  .mindfront-desc,
  .wavculture-desc {
    font-size: 20px;
    position: relative;
  }
  
  
  .skills-title,
  .projects-title {
    text-align: center;
    padding-top: 50px;
    margin-bottom: 50px;
    opacity: .70;
  }
.resume-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    position: relative;
    margin: 5%;
    padding: 3%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .resume-container::before {
    content: "";
    background: linear-gradient(45deg, #c2eaba 0%, #c5f4e0 37%, #efc2cf 100%);
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.30;
  }

.resume-container p {
  font-size: 20px;
  
}

.LI, .GH {
  width: 40px;
  height: auto;
  opacity: .30;
  margin: 0 30px;
}
.skills-icons {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    margin: 5%;
    padding: 3%;
  
  }

  
  .skills-icons img {
    width: 60px;
    height: auto;
    padding-bottom: 15%;
    justify-self: center;
    -webkit-align-self: center;
            align-self: center;
    opacity: .50;
  }

  @media screen and (max-width: 600px) {

    .skills-icons img {
        width: 40px;
    }

  }


.contact-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center; 
    /* background: linear-gradient(319deg, #c2eaba 0%, #c5f4e0 37%, #efc2cf 100%); */
    margin: 5%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
  }
  
  .contact-info::before {
    content: "";
    background: linear-gradient(319deg, #c2eaba 0%, #c5f4e0 37%, #efc2cf 100%);
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.40;
  }

/* .contact-form {
  background-color: white;
  position: relative;
  margin: 3% 20%;
  display: flex;
  flex-direction: column;
} */

.contact-subject-div input,
.contact-name-div input,
.contact-email-div input,
.contact-message-div input {
  width: 500px;
  height: 25px;
  background-color: white;
  position: relative;
  margin: 1%;
  border: 1px solid white;
  font-style: italic;
  padding: 1%;
  border-radius: 5px;
}

.contact-message-div input {
  height: 60px;
  
}

.contact-subject-div,
.contact-name-div,
.contact-email-div,
.contact-message-div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 60%;
  margin: 0 20%;
  -webkit-justify-content: center;
          justify-content: center;
}

.contact-submit {
  width: 200px;
  height: 30px;
  margin: 2%;
  border:none;
  border-radius: 3px;
  background-color: lightgrey;
}

.contact-form h2 {
  margin-top: 30px;
}

.contact-subject-div {
  margin-top: 30px;
}
